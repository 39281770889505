import './App.css';
import logo from "./Assets/images/Logo.jpg";
import { Box, Typography } from '@mui/material';

function App() {
  return (
    <>
      {/* <Navbar1 /> */}
      {/* <NavbarMain /> */}
      <div className="App">
        <header className="App-header">
          <Box className="App-logo">
            <img src={logo} alt="logo" />
          </Box>
          <Typography variant="h3" gutterBottom className="typography-styled" mt={12}>
            Coming <span className="highlighted">Soon...</span>
          </Typography>
          <Typography variant="h5" sx={{ color: '#171859', fontWeight: '600', }} mt={2}>Address: C Ring Road, Doha - Qatar</Typography>
        </header>
      </div>
    </>
  );
}

export default App;
